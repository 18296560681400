<template>
  <div class="mb-3 w-100">
    <v-popover class="w-100">
      <div class="rounded border cursor-pointer w-100">
        <div class="d-flex align-items-center p-3" v-if="userSelected">
          <app-avatar
            class="d-flex align-items-center mr-2"
            :url="userSelected.collaborateur_avatar"
            propSize="3rem"
          ></app-avatar>
          <div class="w-100" style="line-height: 1.1rem">
            <div>{{ userSelected.collaborateur_nom }} {{ userSelected.collaborateur_prenom }}</div>
          </div>
        </div>
        <div v-else class="text-primary cursor-pointer p-3">Sélectionner un utilisateur</div>
      </div>
      <template slot="popover">
        <div class="text-left" style="max-height: 15rem; overflow: auto">
          <div v-for="user in usersFiltered" :key="user.id" class="cursor-pointer" @click="selectUser(user)">
            <div class="d-flex align-items-center p-1" style="width: 372px">
              <app-avatar
                class="d-flex align-items-center mr-2"
                :url="user.collaborateur_avatar"
                propSize="2rem"
              ></app-avatar>
              <div class="mr-3 w-100" style="line-height: 1.1rem">
                <div>{{ user.collaborateur_nom }} {{ user.collaborateur_prenom }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>
<script>
//import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import AppAvatar from "@/components/bases/Avatar.vue";

export default {
  components: { AppAvatar },
  data() {
    return {
      tab: "infos",
      competences: {},
      showForm: false,
    };
  },
  props: {
    value: {
      Type: Number,
      Requierd: false,
    },
    loading: {
      Type: Boolean,
      Requierd: false,
      default: false,
    },
    exclureIds: {
      Type: Array,
      Requierd: false,
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      users: "user/users",
    }),
    userSelected() {
      return this.users.filter((x) => x.id == this.value)[0];
    },
    usersFiltered() {
      if (this.exclureIds) return this.users.filter((e) => !this.exclureIds.some((f) => f.id == e.id));
      return this.users;
    },
  },
  methods: {
    selectUser(data) {
      this.$emit("input", data.id);
    },
  },
};
</script>
<style lang="css">
.tab-item {
  color: #fff;
  padding: 1px;
  cursor: pointer;
}

.tab-item.active {
  color: #007bff;
}

.trigger {
  width: 100%;
}
</style>
